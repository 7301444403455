import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import classNames from 'classnames';

import config from '../../config';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { isAnyFilterActive, isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';

import { Footer, Page, Skeleton } from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';

import FilterComponent from './FilterComponent';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';

import css from './SearchPage.module.css';
import { getOptionsByKey, localBagData } from '../../util/dataExtractor';
import Slider from 'react-slick';
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// SortBy component has its content in dropdown-popup.
// With this offset we move the dropdown a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

const validUrlQueryParamsFromProps = props => {
  const { location, filterConfig } = props;
  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  return {
    ...validURLParamsForExtendedData(searchInURL, filterConfig),
    pub_brand: searchInURL.pub_brand?.toString(),
  };
};

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="46"
        viewBox="0 0 26 46"
        fill="none"
      >
        <path
          d="M21.0232 22.7045L1.67351 3.42001C1.48443 3.2328 1.33502 3.00972 1.23413 2.76399C1.13324 2.51827 1.08292 2.25489 1.08615 1.98946C1.08938 1.72402 1.1461 1.46193 1.25295 1.21872C1.3598 0.975494 1.5146 0.756088 1.70818 0.573494C2.10414 0.19939 2.63107 -0.00629437 3.17699 0.000148634C3.7229 0.00659163 4.24478 0.224651 4.63172 0.607994L25.4114 21.3186C25.5994 21.5044 25.7482 21.7257 25.8493 21.9694C25.9504 22.2132 26.0016 22.4746 26 22.7383C25.9984 23.002 25.9439 23.2627 25.8399 23.5052C25.7359 23.7477 25.5843 23.9672 25.394 24.1507L3.52239 45.4134C3.13085 45.7897 2.60781 46 2.06351 46C1.51922 46 0.99618 45.7897 0.604634 45.4134C0.413318 45.2285 0.261228 45.0072 0.157371 44.7627C0.0535134 44.5183 -7.62527e-08 44.2555 -8.78549e-08 43.9901C-9.94571e-08 43.7247 0.0535134 43.462 0.157371 43.2175C0.261228 42.973 0.413318 42.7518 0.604634 42.5669L21.0232 22.7045Z"
          fill="#3065A1"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      <svg
        style={{
          transform: 'rotate(178deg)',
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="46"
        viewBox="0 0 26 46"
        fill="none"
      >
        <path
          d="M21.0232 22.7045L1.67351 3.42001C1.48443 3.2328 1.33502 3.00972 1.23413 2.76399C1.13324 2.51827 1.08292 2.25489 1.08615 1.98946C1.08938 1.72402 1.1461 1.46193 1.25295 1.21872C1.3598 0.975494 1.5146 0.756088 1.70818 0.573494C2.10414 0.19939 2.63107 -0.00629437 3.17699 0.000148634C3.7229 0.00659163 4.24478 0.224651 4.63172 0.607994L25.4114 21.3186C25.5994 21.5044 25.7482 21.7257 25.8493 21.9694C25.9504 22.2132 26.0016 22.4746 26 22.7383C25.9984 23.002 25.9439 23.2627 25.8399 23.5052C25.7359 23.7477 25.5843 23.9672 25.394 24.1507L3.52239 45.4134C3.13085 45.7897 2.60781 46 2.06351 46C1.51922 46 0.99618 45.7897 0.604634 45.4134C0.413318 45.2285 0.261228 45.0072 0.157371 44.7627C0.0535134 44.5183 -7.62527e-08 44.2555 -8.78549e-08 43.9901C-9.94571e-08 43.7247 0.0535134 43.462 0.157371 43.2175C0.261228 42.973 0.413318 42.7518 0.604634 42.5669L21.0232 22.7045Z"
          fill="#3065A1"
        />
      </svg>
    </div>
  );
}

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isOpenFilterModal: false,
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
    this.onOpenFilterModal = this.onOpenFilterModal.bind(this);
  }

  onOpenFilterModal(modalValue) {
    this.setState({ isOpenFilterModal: modalValue });
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = isOriginInUse(config) ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfig),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal(filterOpen) {
    this.setState({ isMobileModalOpen: filterOpen });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, sortConfig, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  initialValues(queryParamNames, isLiveEdit) {
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState && !isLiveEdit ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, sortConfig, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  // Reset all filter query parameters
  handleResetAll(e) {
    this.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      intl,
      listings,
      filterConfig,
      sortConfig,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      history,
      customListings,
      params,
    } = this.props;

    const brandOptions = customListings?.length && getOptionsByKey(customListings, 'brand');
    const customisedFilterOptions = {
      brandOptions,
    };

    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const availableFilters = isKeywordSearch
      ? filterConfig.filter(f => f.type !== 'KeywordFilter')
      : filterConfig;

    // Selected aka active filters
    const selectedFilters = validFilterParams(validQueryParams, filterConfig);
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;

    const sortBy = mode => {
      const conflictingFilterActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        validQueryParams,
        filterConfig
      );

      const mobileClassesMaybe =
        mode === 'mobile'
          ? {
              rootClassName: css.sortBy,
              menuLabelRootClassName: css.sortByMenuLabel,
            }
          : { className: css.sortByDesktop };
      return sortConfig.active ? (
        <SortBy
          {...mobileClassesMaybe}
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchInURL || {},
      intl
    );

    const hasNoResult = listingsAreLoaded && totalItems === 0;
    const hasSearchParams = location.search?.length > 0;
    const noResultsInfo = hasNoResult ? (
      <div className={css.noSearchResults}>
        <FormattedMessage id="SearchPage.noResults" />
        <br />
        {hasSearchParams ? (
          <button className={css.resetAllFiltersButton} onClick={e => this.resetAll(e)}>
            <FormattedMessage id={'SearchPage.resetAllFilters'} />
          </button>
        ) : null}
      </div>
    ) : null;

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      :classNames(this.state.isOpenFilterModal && css.layoutModalWithTopbar,css.topbar) ;

    const lb = localBagData();

    const loadingResults = (
      <>
        <div className={css.listingCard}>
          <Skeleton width="100%" height={'200px'} />
          <Skeleton width="100%" height={'15px'} />
          <Skeleton width="100%" height={'15px'} />
        </div>
        <div className={css.listingCard}>
          <Skeleton width="100%" height={'200px'} />
          <Skeleton width="100%" height={'15px'} />
          <Skeleton width="100%" height={'15px'} />
        </div>
        <div className={css.listingCard}>
          <Skeleton width="100%" height={'200px'} />
          <Skeleton width="100%" height={'15px'} />
          <Skeleton width="100%" height={'15px'} />
        </div>
        <div className={css.listingCard}>
          <Skeleton width="100%" height={'200px'} />
          <Skeleton width="100%" height={'15px'} />
          <Skeleton width="100%" height={'15px'} />
        </div>
        <div className={css.listingCard}>
          <Skeleton width="100%" height={'200px'} />
          <Skeleton width="100%" height={'15px'} />
          <Skeleton width="100%" height={'15px'} />
        </div>
        <div className={css.listingCard}>
          <Skeleton width="100%" height={'200px'} />
          <Skeleton width="100%" height={'15px'} />
          <Skeleton width="100%" height={'15px'} />
        </div>
      </>
    );
    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
        return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
        className={css.pageRoot}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
          validQueryParams={validQueryParams}
          lb={lb}
        />
        <div
          className={classNames(
            css.layoutWrapperContainer,
            this.state.isOpenFilterModal && css.layoutZindex
          )}
        >
          <MainPanelHeader
            className={css.mainPanel}
            sortByComponent={sortBy('desktop')}
            listingsAreLoaded={listingsAreLoaded}
            resultsCount={totalItems}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            noResultsInfo={noResultsInfo}
            history={history}
            urlQueryParams={validQueryParams}
          />
          <div className={css.layoutWrapperFilterColumn}>
            <div
              className={classNames(
                css.filterColumnContent,
                this.state.isOpenFilterModal && css.filterModalOpen
              )}
            >
              <Slider {...settings}>
                {availableFilters.map(
                  config =>
                    config.label !== 'Sub Category' &&
                    config.label !== 'Availability' && (
                      <FilterComponent
                        key={`SearchFiltersMobile.${config.id}`}
                        idPrefix="SearchFiltersMobile"
                        className={css.filter}
                        filterConfig={config}
                        validQueryParams={validQueryParams}
                        urlQueryParams={urlQueryParams}
                        initialValues={this.initialValues}
                        customisedFilterOptions={customisedFilterOptions}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        history={history}
                        liveEdit
                        showAsPopup={false}
                        isMobileModalOpen={this.state.isMobileModalOpen}
                        isDesktop
                        onOpenFilterModal={this.onOpenFilterModal}
                        onManageDisableScrolling={onManageDisableScrolling}
                      />
                    )
                )}
                {/* <button className={css.resetAllButton} onClick={e => this.handleResetAll(e)}>
                  <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
                </button> */}
              </Slider>
            </div>
          </div>
          <SearchFiltersMobile
            className={css.searchFiltersMobileList}
            urlQueryParams={validQueryParams}
            sortByComponent={sortBy('mobile')}
            listingsAreLoaded={listingsAreLoaded}
            resultsCount={totalItems}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onMapIconClick={onMapIconClick}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={this.onOpenMobileModal}
            onCloseModal={this.onCloseMobileModal}
            resetAll={this.resetAll}
            selectedFiltersCount={selectedFiltersCountForMobile}
            isMapVariant={false}
            noResultsInfo={noResultsInfo}
            onOpenFilterModal={this.onOpenFilterModal}
          >
            {availableFilters.map(config => {
              return (
                <FilterComponent
                  key={`SearchFiltersMobile.${config.id}`}
                  idPrefix="SearchFiltersMobile"
                  filterConfig={config}
                  urlQueryParams={validQueryParams}
                  params={params}
                  isProfile={false}
                  isMobileModalOpen={this.state.isMobileModalOpen}
                  validQueryParams={validQueryParams}
                  initialValues={this.initialValues}
                  customisedFilterOptions={customisedFilterOptions}
                  history={history}
                  getHandleChangedValueFn={this.getHandleChangedValueFn}
                  liveEdit
                  showAsPopup={false}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onOpenFilterModal={this.onOpenFilterModal}
                />
              );
            })}
          </SearchFiltersMobile>
          <MainPanelHeader
            className={css.mainPanel}
            sortByComponent={sortBy('desktop')}
            listingsAreLoaded={listingsAreLoaded}
            resultsCount={totalItems}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            noResultsInfo={noResultsInfo}
            history={history}
            urlQueryParams={validQueryParams}
            shortBy={true}
          />

          <div className={css.layoutWrapperMain} role="main">
            <div className={css.searchResultContainer}>
              <div className={classNames(css.listings)}>
                {searchListingsError ? (
                  <h2 className={css.error}>
                    <FormattedMessage id="SearchPage.searchError" />
                  </h2>
                ) : null}
                <SearchResultsPanel
                  className={css.searchListingsPanel}
                  listings={listings}
                  pagination={listingsAreLoaded ? pagination : null}
                  search={parse(location.search)}
                  isMapVariant={false}
                  loadingResults={loadingResults}
                  searchInProgress={searchInProgress}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    customPageResultIds,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const customListings = getListingsById(state, customPageResultIds);

  return {
    listings: pageListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    customListings,
    searchListingsError,
    searchParams,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
